<template>
  <div class="tags">
    <label class="tags__label">App User Tags:</label>
    <div class="tags-wrapper">
      <b-button v-for="item in tags" :key="item.tag" size="sm" class="tags__item" @click.stop="onSelectTag(item.tag)">
        {{ item.label }}
      </b-button>
    </div>
  </div>
</template>

<script>
// import { NOTI_MESS_TYPE } from '../mixins/communicationForm';
export default {
  name: 'Tags',
  props: {
    messageType: {
      type: Object,
      default: () => { },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tags() {
      return [
        {
          label: 'Tên app user',
          tag: '{{Username}}',
        },
        {
          label: 'Tên gói',
          tag: '{{Packagename}}',
        },
        {
          label: 'Tên huấn luyện viên',
          tag: '{{Coachname}}',
        },
        {
          label: 'Số mục tiêu còn lại (ngày)',
          tag: '{{Goalnumberday}}',
        },
        {
          label: 'Số mục tiêu còn lại (tuần)',
          tag: '{{Goalnumberweek}}',
        },
        {
          label: 'Coachprofile',
          tag: '{{Coachprofile}}'
        }
      ];
    }
    // tags() {
    //   if (!this.isEdit) {
    //     return [
    //       {
    //         label: 'Tên app user',
    //         tag: '{{Username}}',
    //       },
    //     ];
    //   }
    //   if (!this.messageType)
    //     return [
    //       {
    //         label: 'Tên app user',
    //         tag: '{{Username}}',
    //       },
    //       {
    //         label: 'Tên gói',
    //         tag: '{{Packagename}}',
    //       },
    //       {
    //         label: 'Tên huấn luyện viên',
    //         tag: '{{Coachname}}',
    //       },
    //       {
    //         label: 'Số mục tiêu còn lại (ngày)',
    //         tag: '{{Goalnumberday}}',
    //       },
    //       {
    //         label: 'Số mục tiêu còn lại (tuần)',
    //         tag: '{{Goalnumberweek}}',
    //       },
    //     ];
    //   switch (this.messageType.value) {
    //     case NOTI_MESS_TYPE.CREATE_ACCOUNT:
    //     case NOTI_MESS_TYPE.HAPPY_BIRTHDAY:
    //     case NOTI_MESS_TYPE.COACH_APPT_30_MINUTE:
    //     case NOTI_MESS_TYPE.COACH_APPT_1_DAY:
    //     case NOTI_MESS_TYPE.MARKETING:
    //     case NOTI_MESS_TYPE.ACTIVITY:
    //     case NOTI_MESS_TYPE.SURVEY:
    //       return [
    //         {
    //           label: 'Tên app user',
    //           tag: '{{Username}}',
    //         },
    //       ];
    //     case NOTI_MESS_TYPE.JOIN_SERVICE_SUCCESS:
    //       return [
    //         {
    //           label: 'Tên app user',
    //           tag: '{{Username}}',
    //         },
    //         {
    //           label: 'Tên gói',
    //           tag: '{{Packagename}}',
    //         },
    //       ];
    //     case NOTI_MESS_TYPE.JOIN_GROUP:
    //       return [
    //         {
    //           label: 'Tên app user',
    //           tag: '{{Username}}',
    //         },
    //         {
    //           label: 'Tên huấn luyện viên',
    //           tag: '{{Coachname}}',
    //         },
    //       ];

    //     default:
    //       return [
    //         {
    //           label: 'Tên app user',
    //           tag: '{{Username}}',
    //         },
    //         {
    //           label: 'Số mục tiêu còn lại (ngày)',
    //           tag: '{{Goalnumberday}}',
    //         },
    //         {
    //           label: 'Số mục tiêu còn lại (tuần)',
    //           tag: '{{Goalnumberweek}}',
    //         },
    //       ];
    //   }
    // },
  },
  methods: {
    onSelectTag(text) {
      if (!text.length) return;
      this.$emit('select', text);
    },
  },
};
</script>

<style lang="scss" scoped>
.tags {
  display: flex;
  align-items: baseline;
  gap: 8px;

  &__label {
    white-space: nowrap;
  }

  &__item {
    background: #e6f2f8;
    border-color: #e6f2f8;

    &:hover {
      background: #e6f2f8 !important;
      border-color: #e6f2f8 !important;
    }
  }
}

.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
